import { signIn } from "@/data_source/services/APISignIn";
import { getUserMe } from "@/data_source/services/UserService";
import { RouteAppComponentProps } from "@/router/routes";
import { redirect } from "@/router/redirections";
import { AppLoadingStore } from "@/stores/app-loading-store";
import { setPageTitle } from "@/utils/hook";
import { withTransaction } from "@elastic/apm-rum-react";
import { useCallback, useEffect, useState } from "react";
import { catchError, of, switchMap, tap } from "rxjs";
import { AppSignInView } from "./view";

function _AppSignIn(props: RouteAppComponentProps) {
  const [userData, setUserData] = useState<{
    email?: string;
    password?: string;
    remindMe?: boolean;
  }>({});

  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  useEffect(() => {
    const savedEmail = localStorage.getItem("rememberedEmail");
    if (savedEmail) {
      setUserData((prev) => ({
        ...prev,
        email: savedEmail,
        remindMe: true
      }));
    }
  }, []);

  const handleRememberMeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = ev.target.checked;
    setUserData((prev) => ({ ...prev, remindMe: isChecked }));

    if (isChecked && userData.email) {
      localStorage.setItem("rememberedEmail", userData.email);
    } else {
      localStorage.removeItem("rememberedEmail");
    }
  };

  const login = useCallback(() => {
    if (!userData.email || !userData.password) return;

    const subscription = of(true)
      .pipe(
        tap(() => AppLoadingStore.getInstance().add(true)),
        switchMap(() =>
          signIn({
            username: userData.email ?? "",
            password: userData.password ?? ""
          }).pipe(
            tap(() => {
              localStorage.setItem(
                "email",
                JSON.stringify({ email: userData.email ?? "" })
              );
            }),
            catchError((err) => {
              console.error(err);
              setErrorMessage(
                "Error to login, your account or password was not correct"
              );
              AppLoadingStore.getInstance().add(false);
              return of(null);
            })
          )
        ),
        switchMap((signInResponse) => {
          if (!signInResponse?.data) return of(false);

          return getUserMe().pipe(
            tap((userData) => {
              const user = userData?.data;

              if (!user?.is_verified) {
                setErrorMessage("Your account is not active");
                AppLoadingStore.getInstance().add(true);
                redirect(window.location)(
                  "https://wwwdev.sitehubpro.com/sign-up/code-confirmation"
                )("");
                return;
              }

              const config = user.companys_sites?.[0]?.configuration;
              const domain = user.companys_sites?.[0]?.domain;

              if (!config?.registration_completed) {
                setErrorMessage(
                  "Your account does not have any projects configured"
                );
                AppLoadingStore.getInstance().add(true);
                redirect(window.location)(
                  `https://${domain}/sign-up/jobs-types`
                )("");
                return;
              }

              setErrorMessage("");
              AppLoadingStore.getInstance().add(true);
              redirect(window.location)(`https://${domain}`)("");
            }),
            catchError((err) => {
              console.error("Error fetching user data:", err);
              setErrorMessage("Failed to fetch user details.");
              return of(false);
            })
          );
        }),
        tap(() => AppLoadingStore.getInstance().add(false))
      )
      .subscribe();

    return () => {
      if (!subscription.closed) subscription.unsubscribe();
    };
  }, [userData]);

  useEffect(() => {
    setPageTitle(window)(props.pageName)();
  }, []);

  return (
    <AppSignInView
      {...userData}
      errorMessage={errorMessage}
      onChange={(ev) => setUserData((prev) => ({ ...prev, ...ev }))}
      onLoginClick={login}
      togglePasswordVisibility={togglePasswordVisibility}
      showPassword={showPassword}
      handleRememberMeChange={handleRememberMeChange}
    />
  );
}

const AppSignIn = withTransaction("AppSignin", "component")(_AppSignIn);

export default AppSignIn;
