import "@/assets/css/index.css";
import RouterApp from "@/router";
import "@/utils/language";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/core/styles.layer.css";
import { DatesProvider } from "@mantine/dates";
import "@mantine/dates/styles.css";
import "@mantine/dates/styles.layer.css";
import { ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import "mantine-datatable/styles.css";
import "mantine-datatable/styles.layer.css";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router-dom";
import { AppProvider } from "./pages/AppProvider";
import { mainTheme } from "./utils/theme";
import { mantineTheme } from "./utils/theme/mantine";
import request from "./utils/request";

if (import.meta.env.VITE_SENTRY_ENVIROMENT) {
  Sentry.init({
    environment: import.meta.env.VITE_SENTRY_ENVIROMENT,
    dsn: "https://ff0374684eaf4aadf026b04ce06276e4@o249925.ingest.us.sentry.io/4508500651147264",
    // This enables automatic instrumentation (highly recommended)
    // If you only want to use custom instrumentation:
    // * Remove the BrowserTracing integration
    // * add Sentry.addTracingExtensions() above your Sentry.init() call
    integrations: [
      Sentry.browserTracingIntegration(),
      // Or, if you are using react router, use the appropriate integration
      // See docs for support for different versions of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      })
    ],

    // For finer control of sent transactions you can adjust this value, or
    // use tracesSampler
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,

    // Set tracePropagationTargets to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      "/api/",
      "development.sitehubpro.com",
      "staging.sitehubpro.com"
    ]
  });
}

const token = localStorage.getItem("token");
if (token) {
  request.setGlobalHeader({
    Authorization: `Token ${token}`
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider theme={mainTheme}>
      <MantineProvider theme={mantineTheme}>
        <AppProvider>
          <DatesProvider settings={{}}>
            <RouterApp />
          </DatesProvider>
        </AppProvider>
      </MantineProvider>
    </ThemeProvider>
  </React.StrictMode>
);
